import '@/styles/calendar.css';
import '@/styles/index.css';
import '@/styles/map.css';
import '@/styles/nprogress.css';
import '@/styles/react-crop.css';
import '@/styles/responsive.css';
import '@/styles/markdown.css';
import {
  getZendeskKey,
  isZendeskEnabled
} from '@gettactic/helpers/src/zendesk';
import { MantineProvider } from '@mantine/core';
import * as Sentry from '@sentry/nextjs';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'focus-visible';
import { NextPage } from 'next';
import { AppProps as NextAppProps } from 'next/app';
import Head from 'next/head';
import { ReactNode } from 'react';
import { Toaster } from 'react-hot-toast';
import 'react-image-crop/dist/ReactCrop.css';
import NProgress from '@/components/util/Nprogress';
import { useAnalytics } from '@/hooks/useAnalytics';
import { api, showToast } from '@/lib/api/api';
import { ApiClientError } from '@gettactic/api';
import { TacticHooksProvider } from '@gettactic/hooks';
import theme from '../theme/theme';
// import { applyTheme } from "../theme/utils";
// import { DEFAULT_THEME, RED_BULL_THEME } from "../theme";
// import { useRouter } from 'next/router';

type GetLayout = (page: ReactNode) => ReactNode;

// biome-ignore lint/complexity/noBannedTypes: <explanation>
type Page<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: GetLayout;
  extraProps?: {
    hideOfficeMenu?: boolean;
  };
};

// biome-ignore lint/complexity/noBannedTypes: <explanation>
type AppProps<P = {}> = NextAppProps<P> & {
  Component: Page<P>;
};

// Important Note: This has different defaults vs the test framework queryClient
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 // 1 minute default
    }
  },
  mutationCache: new MutationCache({
    onError: (error, _variables, _context, mutation) => {
      // If this mutation has an onError defined, skip this
      if (mutation.options.onError) return;
      if (!process.env.NEXT_PUBLIC_SENTRY_DISABLE) {
        Sentry.captureException(error, {
          tags: {
            category: 'react-query-mutation'
          }
        });
      }
      if (error instanceof ApiClientError) {
        showToast(error.getHumanError());
      } else {
        showToast('An error occurred while trying to submit the data.');
      }
    }
  }),
  queryCache: new QueryCache({
    onError: async (error, query) => {
      if (query.meta?.shouldIgnoreGlobalErrorHandler) {
        // biome-ignore lint/suspicious/noConsoleLog: <explanation>
        console.log('Ignoring error from query', query.queryKey, error);
        return;
      }
      if (query.queryKey.includes('integrations.getResourceCalendars')) {
        return;
      }
      if (
        query.queryKey.includes('teams.forUser') &&
        error instanceof ApiClientError &&
        error.response.status === 403
      ) {
        // ignoring a deleted user
        return;
      }
      if (!process.env.NEXT_PUBLIC_SENTRY_DISABLE) {
        Sentry.captureException(error, {
          tags: {
            category: 'react-query'
          },
          extra: {
            key: query.queryKey
          }
        });
      }
      console.error('TypeError from QueryCache handler', error);
      showToast(
        'An error occurred while fetching the data. Please verify your connection.',
        10
      );
    }
  })
});
// Err required by Sentry
const App = ({
  Component,
  pageProps,
  err
}: AppProps & { err: unknown }): JSX.Element => {
  useAnalytics();

  // const [currentTheme, setCurrentTheme] = React.useState(DEFAULT_THEME)

  // const changeTheme = () => {
  //   if (currentTheme === DEFAULT_THEME) {
  //     setCurrentTheme(RED_BULL_THEME)
  //   } else {
  //     setCurrentTheme(DEFAULT_THEME)
  //   }
  // }

  // useEffect(() => {
  //   applyTheme(currentTheme)
  // }, [currentTheme]);

  // Each Nextjs page component needs to have a static layout property to create persistent layout
  // and avoid re-renders. getLayout either passes the static layout property appended to a page
  // component or passes the page component normally i.e. standard nextjs /pages behavior
  const getLayout =
    Component.getLayout || ((page: ReactNode, pageProps: unknown) => page);
  const propsForLayout = {
    ...pageProps,
    ...Component.extraProps
    // changeTheme,
    // theme: currentTheme
  };

  // Note on the viewport: https://nextjs.org/docs/messages/no-document-viewport-meta in <Head> below
  return (
    <>
      {isZendeskEnabled() && (
        <script
          id="ze-snippet"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${getZendeskKey()}`}
        />
      )}
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>

      <QueryClientProvider client={queryClient}>
        <TacticHooksProvider
          key={api.client.slug ?? 'init'}
          queryClient={queryClient}
          apiClient={api}
        >
          <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
            {getLayout(
              <Component
                {...propsForLayout}
                //@ts-expect-error Ignore err prop
                err={err}
              />,
              propsForLayout
            )}
            <NProgress />
            <Toaster
              position="bottom-center"
              containerStyle={{
                bottom: '40px'
              }}
              toastOptions={{
                style: {
                  background: '#363636',
                  color: '#fff',
                  zIndex: 1
                },
                duration: 3000
              }}
            />
            <ReactQueryDevtools initialIsOpen={false} />
          </MantineProvider>
        </TacticHooksProvider>
      </QueryClientProvider>
    </>
  );
};

export default App;
