import { AuthenticatedUser, Client, Settings } from '@gettactic/api';
import * as Sentry from '@sentry/nextjs';
import { toast } from 'react-hot-toast';

const EXCLUDE_SENTRY_STATUS_CODES = [401, 403, 404];
const devAuthHeader = process.env.NEXT_PUBLIC_UNION_DEV_AUTH
  ? process.env.NEXT_PUBLIC_UNION_DEV_AUTH
  : '';

export function showToast(message: string, seconds = 15) {
  toast.error(message, { id: 'connection-problem', duration: seconds * 1000 });
}

const defaultSettings: Settings = {
  slug: null,
  url: process.env.NEXT_PUBLIC_TACTIC_API_URL
    ? process.env.NEXT_PUBLIC_TACTIC_API_URL
    : 'https://union.gettactic.com',
  debug: !!process.env.NEXT_PUBLIC_TACTIC_API_DEBUG,
  debugAuth: !!process.env.NEXT_PUBLIC_TACTIC_API_DEBUG_AUTH,
  debugGet: !!process.env.NEXT_PUBLIC_TACTIC_API_DEBUG_GET,
  protocol: process.env.NEXT_PUBLIC_TACTIC_DEFAULT_PROTOCOL || 'https',
  domain: process.env.NEXT_PUBLIC_TACTIC_DOMAIN_AND_PORT || 'gettactic.com',
  authHeader:
    process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? '' : devAuthHeader,
  corsBypass: process.env.NEXT_PUBLIC_UNION_CORS_BYPASS
    ? process.env.NEXT_PUBLIC_UNION_CORS_BYPASS
    : '',
  onError: (error: unknown, status?: number) => {
    if (status) {
      if (status === 401) {
        window.location.href = `${process.env.NEXT_PUBLIC_TACTIC_API_URL}/auth/login?returnUrl=${encodeURIComponent(
          `${window.location.origin}/dashboard`
        )}`;
      }
      if (
        process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production' &&
        status !== 404
      ) {
        console.error('Error from api-client:', error, status);
      }

      // If not authorization or 404, send to Sentry to be logged
      if (
        !process.env.NEXT_PUBLIC_SENTRY_DISABLE &&
        !EXCLUDE_SENTRY_STATUS_CODES.includes(status)
      ) {
        Sentry.captureException(error, {
          tags: {
            category: 'api-client'
          }
        });
      }
    }
  }
};

if (process.env.NEXT_PUBLIC_TACTIC_API_DEBUG_AUTH) {
  defaultSettings.legacyAuth = {
    access: process.env.NEXT_PUBLIC_TACTIC_API_DEBUG_AUTH
      ? process.env.NEXT_PUBLIC_TACTIC_API_DEBUG_AUTH
      : '',
    impersonation: null,
    idProvider: 'Id'
  };
}

export const settings: Settings = defaultSettings;

export const embeddedSettings: Settings = {
  slug: null,
  url: settings.url,
  debug: settings.debug,
  debugAuth: settings.debugAuth,
  debugGet: settings.debugGet,
  protocol: settings.protocol,
  domain: settings.domain,
  authHeader: settings.authHeader,
  corsBypass: settings.corsBypass,
  onError: (error: unknown, status?: number) => {
    console.error('error handler:', error, status);
  }
};

export class TacticApiClient {
  private static _instance: TacticApiClient;
  protected settings: Settings;
  protected user: AuthenticatedUser | null;
  public client: Client;

  constructor(settings: Settings) {
    if (TacticApiClient._instance) {
      throw new Error('Singleton cannot be instantiated more than once');
    }
    this.settings = settings;
    this.client = new Client(this.settings);
    this.user = null;
    TacticApiClient._instance = this;
  }

  public cookieExists() {
    return true;
  }

  public setEmbeddedSettings() {
    this.settings = embeddedSettings;
    this.client = new Client(this.settings);
  }

  public updateSlug(slug: string) {
    this.client = new Client({ ...this.settings, slug });
  }

  public updateUser(authenticatedUser: AuthenticatedUser) {
    this.user = authenticatedUser;
    if (!authenticatedUser.organization) {
      throw new Error('Organization is required');
    }
    const slug = authenticatedUser.organization.slug;
    this.client = new Client({ ...this.settings, slug });
  }
}

export const api = new TacticApiClient(settings);
