//@ts-expect-error no types in library
import googleTagManager from '@analytics/google-tag-manager';
import Analytics from 'analytics';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const isProd = process.env.NEXT_PUBLIC_ENV_MODE === 'production';
const isTest = process.env.NODE_ENV === 'test';

// Development Only. No console log for each page unit test.
const devOnlyPlugins = isTest
  ? []
  : [
      {
        name: 'logger',
        page: ({ payload }: any) => {
          console.log('Page Analytics: ', payload);
        },
        track: ({ payload }: any) => {
          console.log('Event Analytics: ', payload);
        },
        identify: ({ payload }: any) => {
          console.log('Identify Analytics: ', payload);
        }
      }
    ];

// Production Only
const prodOnlyPlugins = [
  googleTagManager({
    containerId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID
  })
];

// Initialize analytics and plugins
// Documentation: https://getanalytics.io
export const analytics = Analytics({
  app: 'tactic',
  plugins: isProd ? prodOnlyPlugins : devOnlyPlugins
});

export const useAnalytics = () => {
  const router = useRouter();

  // Super important to have the router.events array for the
  // useEffects hook -- otherwise static optized pages fire twice
  // see: https://github.com/vercel/next.js/issues/12010
  useEffect(() => {
    function onRouteChangeComplete() {
      analytics.page();
    }

    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router.events]);
};
